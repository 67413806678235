const updatePriceEstimate = (qtyInput, costInput, costInputMobile, pricingInformation) => (e) => {
	if (!costInput) return;

	const qty = new Number(qtyInput.value);

	let unitPrice = siteData.product.price;

	if (pricingInformation) {
		pricingInformation.forEach((rule) => {
			const shouldApplyPricingRule =
				qty >= rule.qty.start && qty <= rule.qty.end;

			if (shouldApplyPricingRule) {
				unitPrice = rule.price;
			}
		});
	}
	const price = unitPrice * qty;

	// set value rounded to two decimal places
	const roundedPrice = (Math.round((price + Number.EPSILON) * 100) / 100);
	const priceAsCurrency = (Number.isInteger(roundedPrice) ? roundedPrice : roundedPrice.toFixed(2))
	
	if(costInput.tagName === "INPUT") {
		costInput.value = priceAsCurrency;
	} else {
		costInput.innerText = '£' + priceAsCurrency;
	}
	
	if(costInputMobile) {
		costInputMobile.innerText = '£' + priceAsCurrency;
	}
};

const adjustInputQty = (input, direction) => (e) => {
	e.preventDefault();

	let qty = new Number(input.value);

	if (direction === "increment") {
		qty += 1;
	}

	if (direction === "decrement" && qty > input.min) {
		qty -= 1;
	}

	input.value = qty;
};

const setup = () => {
	const purchaseSections = document.querySelectorAll(".js-purchase-section");

	purchaseSections.forEach((section) => {
		const btns = section.querySelectorAll(".js-set-product-qty");
		const qtyInput = section.querySelector(".js-product-qty");
		const costInput = section.querySelector(".js-cost-input");
		const costInputMobile = section.querySelector(".js-cost-input-mobile");
		let pricingInformation = siteData.product.dynamicPricingInformation;

		if (section.dataset.pricing) {
			pricingInformation = JSON.parse(section.dataset.pricing)
		}

		const fireUpdate = updatePriceEstimate(qtyInput, costInput, costInputMobile, pricingInformation);

		// run on load to ensure correct price according to default value
		fireUpdate();

		qtyInput.addEventListener("change", (e) => {
			if (
				e.currentTarget.min &&
				e.currentTarget.value < new Number(e.currentTarget.min)
			) {
				e.currentTarget.value = e.currentTarget.min;
			}
		});

		btns.forEach((btn) => {
			const direction = btn.dataset.direction;

			if (!direction) return;

			btn.addEventListener("click", adjustInputQty(qtyInput, direction));
			if (qtyInput && costInput) {
				btn.addEventListener("click", fireUpdate);
			}
		});

		if (qtyInput && costInput) {
			qtyInput.addEventListener(
				"input",
				fireUpdate
			);
		}
	});
};

window.addEventListener("DOMContentLoaded", () => setup());
