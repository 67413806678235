import Swiper, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";

Swiper.use([Pagination]);
document.addEventListener("DOMContentLoaded", function () {
	const slides = document.querySelectorAll(".js-swiper-slide");

	if (slides.length <= 1) {
		return;
	}

	const swiper = new Swiper(".js-gallery", {
		loop: true,
		grabCursor: true,
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
});
