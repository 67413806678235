import $ from 'jquery';

$(() => {
	if ($('#js-cute-slider').length) {
		console.log('test');
		const slider = new Cute.Slider();

		slider.setup('js-cute-slider', 'js-cute-slider-wrapper');
	}
});
