import Swiper, { Autoplay, EffectCube, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

Swiper.use([Autoplay, EffectCube, Pagination]);

document.addEventListener("DOMContentLoaded", function () {
	const defaultOptions = {
		speed: 2000,
		loop: true,
	};

	const sliders = [
		{
			sliderEl: ".js-slider",
			slideEl: ".js-swiper-slide",
			options: {
				...defaultOptions,
				effect: "cube",
				autoplay: { delay: 3000 },
			},
		},
		{
			sliderEl: ".js-product-slider",
			slideEl: ".js-product-slide",
			options: {
				...defaultOptions,
				noSwiping: true,
				autoHeight: true,
			},
		},
	];

	sliders.forEach(({ sliderEl, slideEl, options }) => {
		const slides = document.querySelectorAll(slideEl);

		if (slides.length <= 1) return;

		const slider = new Swiper(sliderEl, options);

		document
			.querySelector(".swiper-button-prev")
			.addEventListener("click", () => slider.slidePrev());
		document
			.querySelector(".swiper-button-next")
			.addEventListener("click", () => slider.slideNext());
	});
});
